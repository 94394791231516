import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage as FM } from 'react-intl'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { OneColumnLayout } from '../components/display/oneColumn'
import { Box, Button, Flex, Grid, Modal } from '@makerdao/ui-components-core'
import Link from '../components/link'
import getUrls from '../urls'
import Caret from '../components/caret'

import DaiIcon from '../images/imported/community/icon-dai.svg'
import MakerIcon from '../images/imported/community/icon-maker.svg'
import ResourcesIcon from '../images/imported/community/icon-resources.svg'
import WhitepaperIcon from '../images/imported/community/icon-whitepaper.svg'
import MainLinkCaret from '../images/imported/community/main-link-caret.svg'
import { QRCode } from 'react-qr-svg'
import { getGenericPageTitle } from '../i18n'

const HeroButton = styled(Link)`
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 40px;
  height: 48px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.015em;

  transition: box-shadow 0.2s;
  cursor: pointer;

  background-color: ${props => props.theme.colors.darkPurple};
  color: #f9fafc;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

  :hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    color: #f9fafc;
  }
`

const HeroStyle = styled(Box)`
  overflow: hidden;
  border-radius: 10px;
  padding: 107px 28px 60px;
  position: relative;

  .content {
    max-width: 725px;
  }

  h1 {
    font-weight: bold;
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0.03em;
    margin-bottom: 18px;
    color: #273444;
  }

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    letter-spacing: 0.015em;
  }

  ${HeroButton} {
    margin-top: 23px;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    padding: 127px 48px 65px;

    h1 {
      font-size: 48px;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    .img {
      position: relative;
      left: -40px;
    }

    @media (min-width: 770px) {
      .desktop {
        display: block;
      }
      .mobile {
        display: none;
      }
    }

    @media (max-width: 769px) {
      .img {
        position: relative;
        left: -240px;
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
      }
    }
  }
`

const MainLinkStyle = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding-left: 31px;
  padding-right: 28px;
  height: 80px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: ${props => props.theme.colors.darkPurple};

  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;
  :hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .icon {
    margin-right: 16px;
  }
`

const MainLink = ({ Icon, url, children, style, ...props }) => (
  <Link to={url} style={{ textDecoration: 'none', ...style }} {...props}>
    <MainLinkStyle>
      <Flex alignItems="center">
        <Icon className="icon" />
        {children}
      </Flex>
      <MainLinkCaret />
    </MainLinkStyle>
  </Link>
)

const MainLinks = styled(Grid)`
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 26px;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Column = styled(Box)`
  max-width: 303px;

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: ${props => props.theme.colors.darkPurple};
    margin-bottom: 9px;
  }

  p {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #48495f;
    margin-bottom: 12px;

    @media (min-width: 1004px) {
      min-height: 96px;
    }
  }

  .links {
    padding-left: 2px;

    & > * :not(:last-child) {
      margin-bottom: 4px;
    }
  }
`

const Columns = styled(Flex)`
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  .column:not(:first-child) {
    margin-top: 44px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    align-items: inherit;
    flex-direction: row;

    .column:not(:first-child) {
      margin-top: 0;
      margin-left: 25px;
    }
  }
`

const LINK_COLOR = '#F08B04'
const LINK_COLOR_HOVER = '#E67002'

const StyledCaret = styled(Caret)`
  fill: ${LINK_COLOR};
`

const ColumnLinkStyle = styled(Link)`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.015em;
  color: ${LINK_COLOR};
  text-decoration: none;
  transition: color 0.2s ease;
  cursor: pointer;

  ${StyledCaret} {
    margin-left: 7px;
    transition: fill 0.2s ease;
  }

  :hover {
    color: ${LINK_COLOR_HOVER};
    ${StyledCaret} {
      fill: ${LINK_COLOR_HOVER};
    }
  }
`

const ColumnLink = ({ children, to, ...props }) => (
  <div {...props}>
    <ColumnLinkStyle to={to}>
      {children}
      <StyledCaret direction="right" />
    </ColumnLinkStyle>
  </div>
)

const Community = ({ pageContext: { locale }, data }) => {
  const [showWeChatModal, setShowWeChatModal] = useState(false)
  const urls = getUrls(locale)

  return (
    <Layout
      locale={locale}
      meta={{ title: getGenericPageTitle('Community', locale) }}
    >
      <OneColumnLayout maxWidth="1140px" px="25px" minHeight="0px" mt="38px">
        <HeroStyle>
          <div className="content">
            <h1>
              <FM id="community.hero-title" />
            </h1>
            <p>
              <FM id="community.hero-body" />
            </p>
          </div>
          <div className="background">
            <div className="desktop">
              <Img
                className="img"
                fixed={data.heroBackground.childImageSharp.fixed}
              />
            </div>
            <div className="mobile">
              <Img
                className="img"
                fixed={data.heroBackgroundMobile.childImageSharp.fixed}
              />
            </div>
          </div>
        </HeroStyle>
        <MainLinks mt="32px">
          <MainLink Icon={WhitepaperIcon} url={urls('Whitepaper')}>
            <FM id="community.link-whitepaper" />
          </MainLink>
          <MainLink Icon={DaiIcon} url={urls('Oasis')}>
            <FM id="community.link-get-started-dai" />
          </MainLink>
          <MainLink Icon={MakerIcon} url="https://forum.makerdao.com">
            <FM id="community.link-forum" />
          </MainLink>
          <MainLink Icon={ResourcesIcon} url="https://awesome.makerdao.com/">
            <FM id="community.link-resources" />
          </MainLink>
        </MainLinks>
        <Columns mt="64px" mb="77px">
          <Column className="column">
            <h3>
              <FM id={`community.column1-title`} />
            </h3>
            <p>
              <FM id={`community.column1-body`} />
            </p>
            <div className="links">
              <ColumnLink to="https://chat.makerdao.com/channel/help">
                <FM id="community.link-support" />
              </ColumnLink>
              <ColumnLink to="https://forum.makerdao.com/c/devs">
                <FM id="community.link-dev_forum" />
              </ColumnLink>
              <ColumnLink to={urls('Documentation')}>
                <FM id="community.link-docs" />
              </ColumnLink>
              <ColumnLink to="https://chat.makerdao.com/channel/dev">
                <FM id="community.link-contact_devs" />
              </ColumnLink>
            </div>
          </Column>
          <Column className="column">
            <h3>
              <FM id={`community.column2-title`} />
            </h3>
            <p>
              <FM id={`community.column2-body`} />
            </p>
            <div className="links">
              <ColumnLink to="https://t.me/makerdaoofficial">
                <FM id="community.link-telegram" />
              </ColumnLink>
              <ColumnLink to="https://t.me/DaiDeFi">
                <FM id="community.link-latam_telegram" />
              </ColumnLink>
              <ColumnLink to="https://t.me/Defi_JP">
                <FM id="community.link-japan_telegram" />
              </ColumnLink>
              <ColumnLink
                onClick={() => {
                  setShowWeChatModal(true)
                }}
              >
                <FM id="community.link-wechat" />
              </ColumnLink>
            </div>
          </Column>
        </Columns>
      </OneColumnLayout>
      <Modal
        show={showWeChatModal}
        onClose={() => setShowWeChatModal(false)}
        width="380px"
      >
        <Flex flexDirection="column" alignItems="center">
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ width: '245px', height: '245px' }}
            value={urls('WeChat')}
          />
          <Box fontSize="1.8rem">
            <FM id="menu-wechat-scan-text" />
          </Box>
          <Button mt="m" onClick={() => setShowWeChatModal(false)}>
            Close
          </Button>
        </Flex>
      </Modal>
    </Layout>
  )
}

export default Community

export const query = graphql`
  query {
    heroBackground: file(
      relativePath: { eq: "community/hero-background.png" }
    ) {
      childImageSharp {
        fixed(width: 1184, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroBackgroundMobile: file(
      relativePath: { eq: "community/hero-background.png" }
    ) {
      childImageSharp {
        fixed(height: 628, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

/* eslint-disable global-require */

import Link from './i18nLink'
import { addLocaleData } from 'react-intl'
import messages from './messages'
import languages from './languages'

const defaultLocale = 'en'

languages
  .map(l => require(`react-intl/locale-data/${l.value.split('-')[0]}`))
  .forEach(addLocaleData)

function getMessages(locale) {
  return Object.assign({}, messages[defaultLocale], messages[locale])
}

function getMessage(key, locale) {
  return (
    (messages[locale] && messages[locale][key]) || messages[defaultLocale][key]
  )
}

function getGenericPageTitle(pageName, locale) {
  const subtitle = getMessage('menu-' + pageName, locale) || pageName
  return `Maker - ${subtitle}`
}

export {
  Link,
  languages,
  defaultLocale,
  messages,
  getMessages,
  getMessage,
  getGenericPageTitle,
}
